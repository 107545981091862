import Head from "next/head";
import { useState, useEffect } from "react";
import { useRouter } from "next/router";
import wcsdbanner from "../public/wcsdbanner.png";
import Image from "next/image";
export default function New() {
	const [redirect, setRedirect] = useState(false);
	const router = useRouter();
	const { query } = router;

	async function startRedirect(redirectTo?: string) {
		setRedirect(true);
		const res = await fetch(`/api/auth/loginurl?redirect=${redirectTo || "/dash"}&domain=${window.location.origin}`);
		router.push(await res.text());
	}

	useEffect(() => {
		fetch("/api/auth/me")
			.then((res) => res.json())
			.then((res) => {
				if (!res.error) router.push((query.redirect as string | undefined) || "/dash");
			});
	}, []);

	return (
		<div className="">
			<Head>
				<title>John Jay Chromebook Help Center</title>
			</Head>
			<div className="min-h-screen bg-gray-900 py-20">
				<div className="mx-auto my-10 flex max-w-lg rounded-lg border-4 border-sky-700 bg-gray-800 shadow-lg md:my-24">
					<div className="w-full px-6 pb-6 pt-8 md:px-8">
						<Image src={wcsdbanner} alt={"background image"} />
						<h2 className="mt-6 text-center text-2xl font-semibold text-white">John Jay Chromebook Help Center</h2>
						<p className="text-center text-lg text-gray-200">An @wcsdny.org email is required to continue.</p>

						{!redirect && (
							<>
								<button
									onClick={() => {
										startRedirect(query.redirect as string | undefined);
									}}
									className="mt-4 flex w-full items-center justify-center rounded-lg border border-gray-700 bg-gray-700 text-gray-200 hover:bg-gray-600"
								>
									<div className="px-4 py-2">
										<svg className="h-6 w-6" viewBox="0 0 40 40">
											<path
												d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.045 27.2142 24.3525 30 20 30C14.4775 30 10 25.5225 10 20C10 14.4775 14.4775 9.99999 20 9.99999C22.5492 9.99999 24.8683 10.9617 26.6342 12.5325L31.3483 7.81833C28.3717 5.04416 24.39 3.33333 20 3.33333C10.7958 3.33333 3.33335 10.7958 3.33335 20C3.33335 29.2042 10.7958 36.6667 20 36.6667C29.2042 36.6667 36.6667 29.2042 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z"
												fill="#FFC107"
											/>
											<path
												d="M5.25497 12.2425L10.7308 16.2583C12.2125 12.59 15.8008 9.99999 20 9.99999C22.5491 9.99999 24.8683 10.9617 26.6341 12.5325L31.3483 7.81833C28.3716 5.04416 24.39 3.33333 20 3.33333C13.5983 3.33333 8.04663 6.94749 5.25497 12.2425Z"
												fill="#FF3D00"
											/>
											<path
												d="M20 36.6667C24.305 36.6667 28.2167 35.0192 31.1742 32.34L26.0159 27.975C24.3425 29.2425 22.2625 30 20 30C15.665 30 11.9842 27.2359 10.5975 23.3784L5.16254 27.5659C7.92087 32.9634 13.5225 36.6667 20 36.6667Z"
												fill="#4CAF50"
											/>
											<path
												d="M36.3425 16.7358H35V16.6667H20V23.3333H29.4192C28.7592 25.1975 27.56 26.805 26.0133 27.9758C26.0142 27.975 26.015 27.975 26.0158 27.9742L31.1742 32.3392C30.8092 32.6708 36.6667 28.3333 36.6667 20C36.6667 18.8825 36.5517 17.7917 36.3425 16.7358Z"
												fill="#1976D2"
											/>
										</svg>
									</div>
									<span className="w-5/6 px-4 py-3 text-center font-bold">Sign in with Google</span>
								</button>
							</>
						)}
						{redirect && (
							<>
								<a className="mt-4 flex cursor-wait items-center justify-center rounded-lg border border-gray-700 bg-gray-700 text-gray-200 hover:bg-gray-600">
									<span className="inline-flex w-5/6 justify-center px-4 py-3 text-center font-bold">
										<svg className="h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
											<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
											<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
										</svg>
									</span>
								</a>
							</>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
